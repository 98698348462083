import react from 'react'
import Logo from '../../assets/images/logo.png'
    const Diceloader = () => {
        const diceFaces = [
          ["dotloader dotloader1"],
          ["dotloader dotloader1", "dotloader dotloader2"],
          ["dotloader dotloader1", "dotloader dotloader2", "dotloader dotloader3"],
          ["dotloader dotloader1", "dotloader dotloader2", "dotloader dotloader3", "dotloader dotloader4"],
          ["dotloader dotloader1", "dotloader dotloader2", "dotloader dotloader3", "dotloader dotloader4", "dotloader dotloader5"],
          ["dotloader dotloader1", "dotloader dotloader2", "dotloader dotloader3", "dotloader dotloader4", "dotloader dotloader5", "dotloader dotloader6"],
        ];
    
        return (
          <div className="diceloader">
            {diceFaces.map((dots, index) => (
              <div key={index} className={`dice-faceloader faceloader${index + 1}`}>
                <span>Rush King</span>
                {dots.map((dot, i) => (
                  <div key={i} className={dot}></div>
                ))}
              </div>
            ))}
            {/* {diceFaces.map((dots, index) => (
              <div key={index} className={`dice-faceloader faceloader${index + 1}`}>
                <div className='logoboxdesign'>
                <img src={Logo}/>
                </div>
                {dots.map((dot, i) => (
                  <div key={i} className={dot}></div>
                ))}
              </div>
            ))} */}
          </div>
        );
      };
   
export default Diceloader;