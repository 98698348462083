import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import "../Components/Component-css/Header.css";
import css from "./Component-css/Nav.module.css";
import Downloadbutton from "../Components/Downloadbutton";
import Logo from "../../assets/images/logo.png";
import ReactHtmlParser from "react-html-parser";
import jQuery from "jquery";

const w3_close = () => {
  const width = document.getElementById("mySidebar").offsetWidth;
  document.getElementById("mySidebar").style.left = `-${width}px`;
  document.getElementById("sidebarOverlay").style.display = "none";
};
const w3_open = () => {
  document.getElementById("mySidebar").style.left = "0";
  document.getElementById("sidebarOverlay").style.display = "block";
};

const Header = ({ user, loggedIn }) => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const beckendLiveKYCApiUrl = process.env.REACT_APP_KYC_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
    var baseUrlkyc = beckendLiveKYCApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
    baseUrlkyc = beckendLiveKYCApiUrl;
  }

  const history = useHistory();

  let access_token = localStorage.getItem("token");
  access_token = localStorage.getItem("token");

  let userid = localStorage.getItem("idss");
  userid = localStorage.getItem("idss");

  const [WebSitesettings, setWebsiteSettings] = useState("");

  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    if (data.isWebsiteOn == false) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Website is currently under maintenance. Please try again later.",
      });
      logout();
      return;
    }
    return setWebsiteSettings(data);
  };

  document.title = WebSitesettings
    ? WebSitesettings.WebTitle
    : "HiPLAY WIN REAL CASH";
  useEffect(() => {
    fetchData();
  }, []);

  const logout = () => {
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `logout`,
        {
          headers: headers,
        },
        { headers }
      )
      .then((res) => {
        localStorage.removeItem("token");
        window.location.reload();
        history.push("/");
      })
      .catch((e) => {
        if (e.response?.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
        }
      });
  };



  const [users1, setUsers1] = useState([]);
  const loaduser1 = async (userid) => {
    if (!userid) {
      console.error("User ID is not available");
      return;
    }

    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };

    const url = `${process.env.REACT_APP_KYC_LIVE_API}broadcast-latest-list`;

    try {
      const response = await axios.post(url, { userid: userid }, { headers });
      const objectRes = response.data.data;

      if (objectRes.length > 0) {
        setUsers1(objectRes[0]);
        jQuery("#modal-container").removeClass("hidden").addClass("five");
        jQuery("body").addClass("modal-active");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const viewBroadcast = async () => {
    const access_token = localStorage.getItem("token");
    if (!access_token) {
      console.error("Missing token or user ID in localStorage.");
      return;
    }

    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_KYC_LIVE_API}broadcast-seen-list`, {
        user_id: userid,
        broadcast_id: users1.id,
      }, { headers });
      console.warn("Response received:", response);


      if (response.status == 200) {
        loaduser1(userid);
      } else {
        console.error("Failed to view broadcast:", response.statusText);
      }

    } catch (error) {
      if (error.response) {
        console.error("Failed to view broadcast:", error.response.status, error.response.statusText);
      } else {
        console.error("An error occurred while viewing the broadcast:", error);
      }
    }
  };


  const handleCloseModal = async () => {
    await viewBroadcast();
    jQuery("#modal-container").removeClass("five").addClass("hidden");
    jQuery("body").removeClass("modal-active");
  };


  useEffect(() => {
    loaduser1(userid);
    jQuery(function () {
      jQuery("#closemodle").click(function () {
        jQuery("#modal-container").removeClass("five").addClass("hidden");
        jQuery("body").removeClass("modal-active");
      });
    });
  }, [userid]);

  return (
    <>
      <div>
        {access_token ? (
          <React.Fragment>
            <div id="sidebarOverlay" onClick={w3_close}></div>
            <div
              className="w3-sidebar w3-bar-block"
              id="mySidebar"
              style={{ paddingBottom: "70px" }}
            >
              <Link
                to={"/Profile"}
                className="w3-bar-item w3-button"
                onClick={w3_close}
              >
                <picture className="icon">
                  {user && user.avatar ? (
                    <img
                      width="30px"
                      height="30px"
                      src={baseUrl + `${user && user.avatar}`}
                      alt="profile"
                      style={{ borderRadius: "50px" }}
                    />
                  ) : (
                    <img
                      // src={baseUrl + WebSitesettings.SmallLogo}
                      src="/Images/avatars/Avatar3.png"
                      width="25px"
                      height="25px"
                      alt="profile"
                    />
                  )}
                </picture>
                <div className="ml-2">My Profile</div>

                <picture className="sideNav-arrow">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Images/global-black-chevronRight.png"
                    }
                    alt=""
                  />
                </picture>

                {/* <div className="sideNav-divider"></div> */}
              </Link>
              <Link
                to={"/landing"}
                className="w3-bar-item w3-button"
                onClick={w3_close}
              >
                <picture className="icon">
                  <i className="far  fa-indian-rupee-sign"></i>
                </picture>
                <div className="ml-2">Win cash</div>

                <picture className="sideNav-arrow">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Images/global-black-chevronRight.png"
                    }
                    alt=""
                  />
                </picture>
                {/* <div className="sideNav-divider"></div> */}
              </Link>
              <Link
                to={"/wallet"}
                className="w3-bar-item w3-button"
                onClick={w3_close}
              >
                <picture className="icon">
                  <i class="far  fa-wallet"></i>
                </picture>
                <div className="ml-2">My wallet</div>

                <picture className="sideNav-arrow">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Images/global-black-chevronRight.png"
                    }
                    alt=""
                  />
                </picture>

                {/* <div className="sideNav-divider"></div> */}
              </Link>

              <Link
                to={"/Gamehistory"}
                className="w3-bar-item w3-button"
                onClick={w3_close}
              >
                <picture className="icon">
                  <i class="far   fa-gamepad"></i>
                </picture>
                <div className="ml-2">Game History</div>

                <picture className="sideNav-arrow">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Images/global-black-chevronRight.png"
                    }
                    alt=""
                  />
                </picture>

                {/* <div className="sideNav-divider"></div> */}
              </Link>

              <Link
                to={"/TourmentGamehistory"}
                className="w3-bar-item w3-button"
                onClick={w3_close}
              >
                <picture className="icon">
                  <i class="far   fa-gamepad"></i>
                </picture>
                <div className="ml-2">Tournament Game History</div>

                <picture className="sideNav-arrow">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Images/global-black-chevronRight.png"
                    }
                    alt=""
                  />
                </picture>

                {/* <div className="sideNav-divider"></div> */}
              </Link>

              <Link
                to="/transaction-history"
                className="w3-bar-item w3-button"
                onClick={w3_close}
              >
                <picture className="icon">
                  <i class="far   fa-clock-rotate-left"></i>
                </picture>
                <div className="ml-2">Transaction History</div>

                <picture className="sideNav-arrow">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Images/global-black-chevronRight.png"
                    }
                    alt=""
                  />
                </picture>

                {/* <div className="sideNav-divider"></div> */}
              </Link>

              <Link
                to={"/refer"}
                className="w3-bar-item w3-button"
                onClick={w3_close}
              >
                <picture className="icon">
                  <i class="far   fa-share-nodes"></i>
                </picture>
                <div className="ml-2">Refer and Earn</div>

                <picture className="sideNav-arrow">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Images/global-black-chevronRight.png"
                    }
                    alt=""
                  />
                </picture>

                {/* <div className="sideNav-divider"></div> */}
              </Link>

              <Link
                to={"/Referral-history"}
                className="w3-bar-item w3-button"
                onClick={w3_close}
              >
                <picture className="icon">
                  <i class="far   fa-square-up-right"></i>
                </picture>
                <div className="ml-2">Refer History</div>

                <picture className="sideNav-arrow">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Images/global-black-chevronRight.png"
                    }
                    alt=""
                  />
                </picture>

                {/* <div className="sideNav-divider"></div> */}
              </Link>

              <Link
                to={"/Notification"}
                className="w3-bar-item w3-button"
                onClick={w3_close}
              >
                <picture className="icon">
                  <i class="far  fa-bell"></i>
                </picture>
                <div className="ml-2">Notification</div>

                <picture className="sideNav-arrow">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Images/global-black-chevronRight.png"
                    }
                    alt=""
                  />
                </picture>

                {/* <div className="sideNav-divider"></div> */}
              </Link>

              <Link
                to={"/support"}
                className="w3-bar-item w3-button"
                onClick={w3_close}
              >
                <picture className="icon">
                  <i class="far  fa-headset"></i>
                </picture>
                <div className="ml-2">Support</div>

                <picture className="sideNav-arrow">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Images/global-black-chevronRight.png"
                    }
                    alt=""
                  />
                </picture>

                {/* <div className="sideNav-divider"></div> */}
              </Link>
              <Link
                to={"/all-policy"}
                className="w3-bar-item w3-button"
                onClick={w3_close}
              >
                <picture className="icon">
                  <i class="far  fa-file-alt"></i>
                  {/* <img
                  src={process.env.PUBLIC_URL + "/Images/terms.png"}
                  alt=""
                /> */}
                </picture>
                <div className="ml-2">All Policy</div>
                <picture className="sideNav-arrow">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Images/global-black-chevronRight.png"
                    }
                    alt=""
                  />
                </picture>
                {/*<div className="sideNav-divider"></div> */}
              </Link>

              <Link
                to={"/login"}
                className="w3-bar-item w3-button"
                onClick={(e) => logout(e)}
              >
                <picture className="icon">
                  <i class="far  fa-sign-out"></i>
                </picture>
                <div className="ml-2">Log Out</div>
                <picture className="sideNav-arrow">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Images/global-black-chevronRight.png"
                    }
                    alt=""
                  />
                </picture>
                {/*<div className="sideNav-divider"></div> */}
              </Link>

              {/*   <Link className="w3-bar-item w3-button" to="!/" onClick={(e) => logout(e)}>
          <picture className="icon">
            <img alt="img" src={process.env.PUBLIC_URL + '/Images/logout.png'} />
          </picture>
          <div style={{ marginLeft: '.5rem' }}>
            Logout
          </div>

        </Link> */}
            </div>

            <div className="w3-teal">
              <div className="w3-container ">
                <div className={`${css.headerContainer} `}>
                  <button
                    className="w3-button w3-xlarge float-left"
                    onClick={w3_open}
                    id="hambergar"
                  >
                    <picture className={`${css.sideNavIcon} mr-0`}>
                      <img
                        src="/Images/Homepage/menubar.png"
                        className="snip-img"
                        alt="Bar"
                      />
                      {/* <i className="far fa-bars"></i> */}
                    </picture>
                  </button>
                  <Link to="/">
                    <picture className={`ml-2 ${css.navLogo} d-flex`}>
                      <img src={Logo} className="snip-img" alt="logo" />
                    </picture>
                  </Link>
                  <div>
                    <div className={`${css.menu_items}`}>
                      <div className="bell">
                        <Downloadbutton />
                      </div>

                      <Link className={`wallet_cash ${css.box}`} to="/Addcase">
                        <i className="far fa-wallet mr-2"></i>
                        <i className="fa fa-rupee text-light"></i>
                        <div className="ml-1">
                          {/* <div className={`${css.moneyBox_header}`}>Cash</div> */}
                          <div className={`${css.moneyBox_text}`}>
                            {user && user.Wallet_balance}
                          </div>
                        </div>
                        <div className="p-1 add_btn">
                          <i className="far fa-plus"></i>
                        </div>
                      </Link>
                      {/* <Link
                      className={`${css.box} rounded-pill ml-2`}
                      to="/Notification"
                    >
                      <div className="bell">
                        <i className="far fa-bell"></i>
                      </div>
                    </Link> */}
                      {/* <Link
                      className={`${css.box} ml-2`}
                      to="/redeem/refer"

                    >
                      <picture className={`${css.moneyIcon_container}`}>
                        <i style={{ color: "#ffffff", paddingLeft: "5px" }} class="far fa-gift"></i>
                      </picture>
                      <div className="mt-1 ml-1">
                        <div className={`${css.moneyBox_header}`}>Earning</div>
                        <div className={`${css.moneyBox_text}`}>
                          {user && user.referral_wallet}
                        </div>
                      </div>
                    </Link> */}
                    </div>
                    <span className="mx-5"></span>
                  </div>
                  <span className="mx-5"></span>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <div className="w3-teal ">
            <div className="w3-container ">
              <div className={`${css.headerContainer} justify-content-between`}>
                <Link to="/">
                  <picture className={`ml-2 ${css.navLogo} d-flex`}>
                    <img src={Logo} className="logo" alt="logo" />
                  </picture>
                </Link>

                <div className={`ml-5`}>
                  <Link type="button" className="login-btn rounded" to="/login">
                    LOGIN
                  </Link>
                </div>
              </div>
              <div className={`${css.headerContainer} justify-content-between`}>
                <Link to="/">
                  <picture className={`ml-2 ${css.navLogo} d-flex`}>
                    <img src={Logo} className="logo" alt="logo" />
                  </picture>
                </Link>

                <div className={`ml-1 d-flex`}>
                  <div className="downloadButton mr-2">
                    <Downloadbutton />
                  </div>
                  <Link type="button" className="login-btn" to="/login">
                    LOGIN
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div>
        {/* Modal */}
        <div id="modal-container" className="hidden">
          <div className="popupnew modal-overlay">
            <div className="new_five">
              <div className="modal-background position-relative">

                <div className="modal-content">
                  <div className="modal_header">
                    <h2 className="modal_title">Notification</h2>
                    <button onClick={handleCloseModal} id="closemodle">
                      <i className="fa fa-close"></i>
                    </button>
                  </div>
                  {/* <div className="logo_ludo">
                    <img src={Logo} alt="Logo" className="img-fluid" />
                  </div> */}
                  <h2 className="updateversion">{users1 && users1.title}</h2>
                  <p className="updateversion_sec">
                    {ReactHtmlParser(users1 && users1.message)}
                    {users1 && users1.type === "Video" ? (
                      <video controls width="100%" height="150px">
                        <source src={users1.media} />
                      </video>
                    ) : null}
                    {users1 && users1.type === "Image" ? (
                      <div>
                        <img src={users1.media} alt="User" className="img-fluid" />
                      </div>
                    ) : null}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default Header;
