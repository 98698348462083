import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Homepage from "./uiComponents/Homepage";
import Tournament from "./uiComponents/Tournament";
import Landing from "./uiComponents/Landing";
import userLogin from "./uiComponents/Login";
import Mywallet from "./uiComponents/Mywallet";

import Addcase from "./uiComponents/Addcase";
import Addcase2 from "./uiComponents/Addcase2";
import Footer from "./uiComponents/Footer";
import Withdrawopt from "./uiComponents/Withdrawopt";
import Profile1 from "./uiComponents/Profile1";
import ViewGame1 from "./uiComponents/ViewGame1";
import TourmentResult from "./uiComponents/TourmentResult";
import Gamehistory from "./uiComponents/Gamehistory";
import TourmentGamehistory from "./uiComponents/TourmentGamehistory";
import "animate.css";
import axios from "axios";

import Transactionhistory from "./uiComponents/Transactionhistory";

import Referralhis from "./uiComponents/Referralhis";
import Refer from "./uiComponents/Refer";
import Notification from "./uiComponents/Notification";
import Support from "./uiComponents/Support";

import Kyc2 from "./uiComponents/Kyc2";
import RefundPolicy from "./uiComponents/RefundPolicy";
import terms_condition from "./uiComponents/terms_condition";
import PrivacyPolicy from "./uiComponents/PrivacyPolicy";
import Gamerules from "./uiComponents/Gamerules";
import ResponsibleGaming from "./uiComponents/ResponsibleGaming";
import Return from "./uiComponents/Return";
import Notify from "./uiComponents/Notify";
import Header from "./Components/Header";
import Rightcontainer from "./Components/Rightcontainer";

import Redeem from "./uiComponents/Redeem";
import AboutUs from "./uiComponents/AboutUs";
import All_policy from "./uiComponents/All_policy";
import Manual_Payment from "./uiComponents/Manual_Payment";

const App2 = () => {
  useEffect(() => {
    if (process.env.REACT_APP_DEBUG === "true") {
    }
  }, []);

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const access_token = localStorage.getItem("token");
  const [user, setUser] = useState();
  const getUser = () => {
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUser(res.data);
      })
      .catch((e) => {
        if (e.response?.status === 401) {
          localStorage.removeItem("token");
          // history.pushState("/login")
        }
      });
  };
  useEffect(() => {
    getUser();
  }, []);
  return (
    <>
      <div className="leftContainer">
        <div>
          <Header user={user} />
        </div>
      </div>

      {!access_token ? (
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route path="/landing" component={Landing} />
          <Route path="/login" component={userLogin} />
          <Route path="/RefundPolicy" component={RefundPolicy} />
          <Route path="/PrivacyPolicy" component={PrivacyPolicy} />
          <Route path="/term-condition" component={terms_condition} />
          <Route path="/refund-policy" component={RefundPolicy} />
          <Route path="/contact-us" component={Support} />
          <Route path="/Gamerules" component={Gamerules} />
          <Route path="/responsible-gaming" component={ResponsibleGaming} />
          <Redirect to="/login" />
        </Switch>
      ) : (
        <Switch>
          <Route path="/transaction-history" component={Transactionhistory} />
          <Route exact path="/Referral-history" component={Referralhis} />
          <Route exact path="/landing" component={Landing} />
          <Route exact path="/Gamehistory" component={Gamehistory} />
          <Route exact path="/TourmentGamehistory" component={TourmentGamehistory} />
          <Route path="/all-policy" component={All_policy} />
          <Route
            exact
            path="/HomePage/:Game"
            render={() => <Homepage walletUpdate={getUser} />}
          />

          <Route
            exact
            path="/tournament"
            render={() => <Tournament walletUpdate={getUser} />}
          />
          <Route exact path="/refer" component={Refer} />
          <Route exact path="/Notification" component={Notification} />
          <Route exact path="/" component={Landing} />

          <Route path="/profile" component={Profile1} />
          <Route path="/viewgame1/:id" component={ViewGame1} />
          <Route path="/tourmentresult/:playerid" component={TourmentResult} />
          <Route
            path="/addcase"
            render={() => <Addcase walletUpdate={getUser} />}
          />
          <Route
            path="/addcase2/:rechargeAmt/:payerName/:payerEmail/:payerMobile"
            render={() => <Addcase2 walletUpdate={getUser} />}
          />
          <Route path="/Manual_Payment" component={Manual_Payment} />
          <Route
            path="/Withdrawopt"
            render={() => <Withdrawopt walletUpdate={getUser} />}
          />
          <Route path="/wallet" component={Mywallet} />
          <Route path="/support" component={Support} />

          <Route exact path="/landing/:id" component={Landing} />
          <Route path="/kyc2" render={() => <Kyc2 user={user} />} />
          <Route path="/Rules" component={Gamerules} />
          <Route path="/RefundPolicy" component={RefundPolicy} />
          <Route path="/PrivacyPolicy" component={PrivacyPolicy} />
          <Route path="/term-condition" component={terms_condition} />

          <Route
            path="/return"
            render={() => <Return walletUpdate={getUser} />}
          />
          <Route path="/notify" component={Notify} />

          <Route
            path="/Redeem"
            render={() => <Redeem walletUpdate={getUser} />}
          />
          <Route path="/contact-us" component={Support} />
          <Route path="/refund-policy" component={RefundPolicy} />
          <Route path="/Gamerules" component={Gamerules} />
          <Route path="/responsible-gaming" component={ResponsibleGaming} />
          <Route path="/about" walletUpdate={getUser} component={AboutUs} />

          <Redirect to="/landing" />
        </Switch>
      )}
      <div className="rightContainer">
        <Rightcontainer />
      </div>

      {window.location.pathname !== "/login" && (
        <div>
          <Footer />
        </div>
      )}
    </>
  );
};
export default App2;
