import React, { useState, useEffect } from "react";
import css from "../css/gamehis.module.css";
import ReactPaginate from "react-paginate";
import Rightcontainer from "../Components/Rightcontainer";
import axios from "axios";

const TourmentGamehistory = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const [user, setUser] = useState();
  let limit = 50;
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios.get(baseUrl + `me`, { headers }).then((res) => {
      setUser(res.data);
      Allgames(res.data._id, pageNumber, limit);
    });
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
  };
  const [game, setGame] = useState([]);

  const Allgames = async (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(
        baseUrl + `tournament-myplayed-list/${id}`,
        { headers }
      )
      .then((res) => {

        setGame(res.data.mytournamentlist);
        setNumberOfPages(res.data.totalPages);
      });
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    const newDate = date.toLocaleString("default", {
      month: "short",
      day: "numeric",
      hour: "numeric",
      hour12: true,
      minute: "numeric",
    });
    return newDate;
  };

  useEffect(() => {
    role();
    //eslint-disable-next-line
  }, [pageNumber, limit]);

  return (
    <div>
      <div
        className="leftContainer"
        style={{ minHeight: "100vh", height: "100%" }}
      >
        <div className="paddingallpage">


          {/* game-cards */}
          {game &&
            game.map((card) => (
              <div
                className={`bethistorydesign w-100 mt-3 py-3 d-flex align-items-center ${css.list_item}`}
              >

                <div className={`${css.center_xy} ${css.list_date} mx-1 text-light`}>
                  <small>
                    <div>{dateFormat(card.createdAt).split(",")[0]}</div>
                  </small>
                  <small>
                    <small>{dateFormat(card.createdAt).split(",")[1]}</small>
                  </small>

                </div>
                <div className={`${css.list_divider_y}`} />
                <div className={`mx-1 d-flex ${css.list_body} w-50`}>
                  <div className="d-flex align-items-center">
                    <picture className="mr-2">
                      <img
                        height="30px"
                        width="30px"
                        src={process.env.PUBLIC_URL + `/Images/LandingPage_img/ludo.jpeg`}
                        alt=""
                        style={{ borderRadius: "5px" }}
                      />
                    </picture>
                    <div className="d-flex flex-column font-8">
                      <div className={`${css.games_section_headline} text-light`}>
                        Tournament id: {card._id}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mx-1 amount_result">
                  <div className="d-flex w-100">
                    Game Amount: <span className="ms-1">{card.Game_Ammount}</span>
                  </div>
                  <div className="d-flex">Result: 
                  <span style={{
                        color: card.result == "win" || card.result === "winn" ? "green" :
                          card.result === "lose" ? "red" :
                            card.result === "awaiting" ? "orange" : "black"
                      }}>{card.result}</span>
                     </div> 
                    {/* <span className="ms-1" style={{color:"red"}}>{card.result}</span>*/}
                  <div className="d-flex">Win Prize: <span className="ms-1">{card.winnerPrize}</span></div>
                </div>


              </div>
            ))}
          {game && game.length === 0 && (
            <div className="text-center">
              <img
                src={process.env.PUBLIC_URL + "/Images/nogamehistory.png"}
                alt="no data"
                width={300}
                height={300}
                className="img-fluid "
                style={{ marginTop: "25%" }}
              />
              <div className="mt-2">
                <h3 className="font-weight-bold">No Game History</h3>
                <p> You have no game history yet.</p>
              </div>
            </div>
          )}
          <div className="margin-top-all">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={numberOfPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};

export default TourmentGamehistory;
