import React, { useState, useEffect, useRef } from "react";
import css from "../css/tournament.module.css";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import FireworksCanvas from '../Components/FireworksCanvas';
import Diceloader from "./Diceloader";
import { data } from "jquery";

const Tournament = () => {
  const [activeTab, setActiveTab] = useState("winnerPrizes");
  const [activeTab1, setActiveTab1] = useState("joinerlist");
  const [tournamentData, setTournamentData] = useState([]);
  const [showWinnerPrizes, setshowWinnerPrizes] = useState([]);
  const [showFirstPrize, setshowFirstPrize] = useState([]);
  const [winnerPrizes, setWinnerPrizes] = useState({});
  const [winnerPrizesFirst, setWinnerPrizesFrist] = useState(0);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [enteyFee, setEnteyFee] = useState({});
  const [tournamentIds, setTournamentId] = useState(null);
  const [tourmentJoinLenght, settourmentLenght] = useState(0);
  const [pairJoin, setPairJoin] = useState([]);
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState(null);
  const [pairedlistID, setpairedlistID] = useState(null);
  const [playerid, setPlayerid] = useState([]);
  const [isJoining, setIsJoining] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userResult, setUserResult] = useState(null);
  const [userLevel, setUserLevel] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const tournamentsPerPage = 5;
  const totalTournaments = tournamentData.length;
  const totalPages = Math.ceil(totalTournaments / tournamentsPerPage);
  const indexOfLastTournament = currentPage * tournamentsPerPage;
  const indexOfFirstTournament = indexOfLastTournament - tournamentsPerPage;
  const currentTournaments = tournamentData.slice(indexOfFirstTournament, indexOfLastTournament);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const baseUrl = process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BACKEND_LOCAL_API
    : process.env.REACT_APP_BACKEND_LIVE_API;

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUserId(res.data._id);
        setUserName(res.data.Name);
      })
      .catch((e) => {
        console.log("Error");
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    role();
  })

  useEffect(() => {
    fetchTournamentData();
  }, []);

  const fetchTournamentData = async () => {
    try {
      const access_token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${access_token}` };
      const response = await axios.get(`${baseUrl}tournament-list`, { headers });

      const tournaments = response.data.TournamentModeldata || [];
      console.warn(tournaments);

      if (tournaments.length > 0) {
        setEnteyFee(tournaments[0].entry_fees);
        setTournamentId(tournaments[0].t_id);
        localStorage.setItem("tidss", tournaments[0].t_id);
        setWinnerPrizes(response.data.TournamentWinnerPrizedata[0]);
        setWinnerPrizesFrist(response.data.TournamentWinnerPrizedata[0].winnerPrize1);
        fetchLeaderboardData(tournaments[0].t_id);
        setTournamentData(tournaments);

      } else {
        console.warn("No tournaments available.");
      }
    } catch (error) {
      console.error("Error fetching tournament data:", error);
    }
  };

  useEffect(() => {
    fetchTournamentWinnerData();
  }, []);
  const fetchTournamentWinnerData = async () => {
    const tournamentIds = localStorage.getItem("tidss");
    try {
      const access_token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${access_token}` };
      const response = await axios.post(
        `${baseUrl}tournament-winner-prize-list`,
        { tournamentid: tournamentIds },
        { headers }
      );

      const tournaments = response.data.data;
      setshowWinnerPrizes(tournaments);
      setshowFirstPrize(tournaments[0].prize);
      console.warn(tournaments);

      if (!tournaments || tournaments.length === 0) {
        console.warn("No tournaments available.");
      }
    } catch (error) {
      console.error("Error fetching tournament data:", error);
    }
  };


  const TournamentPairJoin = async () => {
    if (!tournamentIds) {
      console.warn("Tournament ID is not set. Skipping TournamentPairJoin.");
      return;
    }

    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    try {
      const res = await axios.post(
        `${baseUrl}tournament-pair-and-join-list`,
        { tournament_id: tournamentIds },
        { headers }
      );

      const currentUserId = localStorage.getItem("idss");

      const hasJoined = res.data.Joinlist.some(
        (joiner) => joiner.userid === currentUserId
      );

      setPairJoin(res.data.pairedlist);
      console.warn(hasJoined);
      setIsJoining(hasJoined);

    } catch (e) {
      console.error("Error in TournamentPairJoin:", e);
    }
  };


  useEffect(() => {
    TournamentPairJoin();
  }, [tournamentIds]);

  const handleJoinNow = (tournamentId) => {
    console.log(`Joining tournament: ${tournamentId}`);
    axios
      .post(
        `${baseUrl}tournament-join`,
        { tournament_id: tournamentId, Game_type: "tournament", Game_Ammount: enteyFee },
        { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
      )
      .then((res) => {
        Swal.fire({
          title: res.data.msg,
          icon: res.data.status ? "success" : "warning",
          confirmButtonText: "OK",
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        console.error(error);
        alert("Failed to join the tournament.");
      });

  };


  const fetchLeaderboardData = async (tournamentId) => {
    try {
      const access_token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${access_token}` };
      const response = await axios.post(
        `${baseUrl}tournament-join-player-list`,
        { tournament_id: tournamentId },
        { headers }
      );
      const data = response.data.TournamentModeldata || [];
      settourmentLenght(response.data.TournamentModeldata.length);
      setLeaderboardData(response.data.TournamentModeldata || []);


    } catch (error) {
      console.error("Error fetching leaderboard data:", error);
    }
  };


  const resultBanner = async () => {
    try {
      const access_token = localStorage.getItem("token");
      const currentUserId = localStorage.getItem("idss");
      const tournamentIds = localStorage.getItem("tidss");

      const headers = { Authorization: `Bearer ${access_token}` };

      const response = await axios.post(
        `${baseUrl}tournament-self-result`,
        { tournament_id: tournamentIds, userid: currentUserId },
        { headers }
      );
      setUserResult(response.data.result);
    } catch (error) {
      console.error("Error fetching leaderboard data:", error);
    }
  };


  useEffect(() => {
    resultBanner();
  }, []);

  const isUserPaired = pairJoin.some(
    (pair) => pair.creater_userid == userId || pair.joiner_userid == userId
  );

  useEffect(() => {
    const userPair = pairJoin.find(
      (pair) => pair.creater_userid == userId || pair.joiner_userid == userId
    );

    if (userPair && userPair._id !== playerid) {
      setPlayerid(userPair._id);
    }

  }, [userId, pairJoin, playerid]);


  const createGlitter = (container) => {
    const glitter = document.createElement("div");
    glitter.classList.add("glitter");
    glitter.style.left = Math.random() * 100 + "%";
    glitter.style.animationDuration = Math.random() * 3 + 2 + "s";

    container.appendChild(glitter);
    setTimeout(() => glitter.remove(), 5000);
  };


  const createStarGlitter = (container) => {
    const starGlitter = document.createElement("div");
    starGlitter.classList.add("star-glitter");
    starGlitter.style.left = Math.random() * 100 + "%";
    starGlitter.style.animationDuration = Math.random() * 3 + 2 + "s";

    container.appendChild(starGlitter);
    setTimeout(() => starGlitter.remove(), 5000);
  };


  const createSpark = (container) => {
    for (let i = 0; i < 5; i++) {
      const spark = document.createElement("div");
      spark.classList.add("blast");
      const angle = Math.random() * 360;
      const distance = Math.random() * 150 + 50; // Between 50px and 200px
      const xOffset = distance * Math.cos((angle * Math.PI) / 180);
      const yOffset = distance * Math.sin((angle * Math.PI) / 180);
      spark.style.setProperty("--x", `${xOffset}px`);
      spark.style.setProperty("--y", `${yOffset}px`);

      container.querySelector(".blast-container").appendChild(spark);


      setTimeout(() => spark.remove(), 1500);
    }
  };


  useEffect(() => {
    const winnerContainers = document.querySelectorAll(".winnersize");
    const glitterIntervals = [];
    const sparkIntervals = [];


    winnerContainers.forEach((container) => {

      const glitterInterval = setInterval(() => {
        createGlitter(container);
        createStarGlitter(container);
      }, 500);


      const sparkInterval = setInterval(() => {
        createSpark(container);
      }, 1000);


      glitterIntervals.push(glitterInterval);
      sparkIntervals.push(sparkInterval);
    });


    return () => {
      glitterIntervals.forEach(clearInterval);
      sparkIntervals.forEach(clearInterval);
    };
  }, []);


  const Dice = () => {
    const diceFaces = [
      ["dot dot1"],
      ["dot dot1", "dot dot2"],
      ["dot dot1", "dot dot2", "dot dot3"],
      ["dot dot1", "dot dot2", "dot dot3", "dot dot4"],
      ["dot dot1", "dot dot2", "dot dot3", "dot dot4", "dot dot5"],
      ["dot dot1", "dot dot2", "dot dot3", "dot dot4", "dot dot5", "dot dot6"],
    ];

    return (
      <div className="dice">
        {diceFaces.map((dots, index) => (
          <div key={index} className={`dice-face face${index + 1}`}>
            {dots.map((dot, i) => (
              <div key={i} className={dot}></div>
            ))}
          </div>
        ))}
      </div>
    );
  };



  return (
    <div className="leftContainer" style={{ minHeight: "100vh" }}>
      <div
        className={css.mainArea}
        style={{ paddingTop: "60px", minHeight: "100vh" }}
      >

        {isLoading ? (

          <div className="loaderReturn" style={{ zIndex: '99', minHeight: '100vh' }}>

            {/* <img
              src={process.env.PUBLIC_URL + '/Images/LandingPage_img/loaderhi.gif'}
              style={{ width: '100%' }}
              alt="Loading..."
            /> */}
            <div>
              <Diceloader />
              <div className="d-flex align-items-center gap-1 position-relative top-40">
                <div className="pleasewait">Please Wait</div>
                <div class="loading-dots">
                  <div class="loading-dots--dot"></div>
                  <div class="loading-dots--dot"></div>
                  <div class="loading-dots--dot"></div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={css.tournamentContainer}>

            {tournamentData.length > 0 ? (
              tournamentData.map((tournament, index) => (
                <>
                  <div key={index} className={css.tournament}>

                    <div className="bg-white rounded">
                      <div className='winner_tab'>
                        <button
                          className={`${css.tabButton} ${activeTab === "winnerPrizes" ? css.activeTab : ""}`}
                          onClick={() => setActiveTab("winnerPrizes")}
                        >
                          Details
                        </button>
                        <button
                          className={`${css.tabButton} ${activeTab === "leaderboard" ? css.activeTab : ""}`}
                          onClick={() => {
                            setActiveTab("leaderboard");

                          }}
                        >
                          Leaderboard
                        </button>
                      </div>
                      {activeTab === "winnerPrizes" && (
                        <div className="">
                          <div className="w-100 mt-2">
                            <div className="detail">
                              <p>
                                <i className="fa fa-heart"></i>
                                You have {tournament.total_user} out of {tournament.total_user - tourmentJoinLenght} entries left</p>
                            </div>

                            <div className="resultTournament" >
                              {
                                userResult == "lose" ? (
                                  <div class="winnersize">
                                    <div class="winner-container">
                                      <div class="icon-container">
                                        <div class="d-flex gap-2 badluck">
                                          <div class="content_design">
                                            <div class="blast-container"></div>

                                            <div class="winner-header">
                                              <div class="dice-container losethematch">
                                                <div class="dice">
                                                  <div class="dice-face face1">
                                                    <div class="dot dot1"></div>
                                                  </div>
                                                  <div class="dice-face face2">
                                                    <div class="dot dot1"></div>
                                                    <div class="dot dot2"></div>
                                                  </div>
                                                  <div class="dice-face face3">
                                                    <div class="dot dot1"></div>
                                                    <div class="dot dot2"></div>
                                                    <div class="dot dot3"></div>
                                                  </div>
                                                  <div class="dice-face face4">
                                                    <div class="dot dot1"></div>
                                                    <div class="dot dot2"></div>
                                                    <div class="dot dot3"></div>
                                                    <div class="dot dot4"></div>
                                                  </div>
                                                  <div class="dice-face face5">
                                                    <div class="dot dot1"></div>
                                                    <div class="dot dot2"></div>
                                                    <div class="dot dot3"></div>
                                                    <div class="dot dot4"></div>
                                                    <div class="dot dot5"></div>
                                                  </div>
                                                  <div class="dice-face face6">
                                                    <div class="dot dot1"></div>
                                                    <div class="dot dot2"></div>
                                                    <div class="dot dot3"></div>
                                                    <div class="dot dot4"></div>
                                                    <div class="dot dot5"></div>
                                                    <div class="dot dot6"></div>
                                                  </div>
                                                </div>
                                              </div>
                                              <span class="linear-wipe"> Better Luck Next Time! </span>
                                              <div class="dice-container losethematch right">
                                                <div class="dice">

                                                  <div class="dice-face face1">
                                                    <div class="dot dot1"></div>
                                                  </div>
                                                  <div class="dice-face face2">
                                                    <div class="dot dot1"></div>
                                                    <div class="dot dot2"></div>
                                                  </div>
                                                  <div class="dice-face face3">
                                                    <div class="dot dot1"></div>
                                                    <div class="dot dot2"></div>
                                                    <div class="dot dot3"></div>
                                                  </div>
                                                  <div class="dice-face face4">
                                                    <div class="dot dot1"></div>
                                                    <div class="dot dot2"></div>
                                                    <div class="dot dot3"></div>
                                                    <div class="dot dot4"></div>
                                                  </div>
                                                  <div class="dice-face face5">
                                                    <div class="dot dot1"></div>
                                                    <div class="dot dot2"></div>
                                                    <div class="dot dot3"></div>
                                                    <div class="dot dot4"></div>
                                                    <div class="dot dot5"></div>
                                                  </div>
                                                  <div class="dice-face face6">
                                                    <div class="dot dot1"></div>
                                                    <div class="dot dot2"></div>
                                                    <div class="dot dot3"></div>
                                                    <div class="dot dot4"></div>
                                                    <div class="dot dot5"></div>
                                                    <div class="dot dot6"></div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <div class="winner-message w-100">
                                              Better Luck Next Time
                                              <div class="main-icon">👎  </div>
                                              <div class="main-icon left" >👎  </div>

                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="sadgiff">
                                        <img src={process.env.PUBLIC_URL + "/Images/banner/sadgiff.gif"} alt="" />

                                      </div>
                                      <div class="sadgiff left">
                                        <img src={process.env.PUBLIC_URL + "/Images/banner/sadgiff.gif"} alt="" />
                                      </div>

                                    </div>
                                  </div>


                                ) : userResult == "winn" && tournament.tournamentComplete == 'complete' ? (
                                  <div className="winnersize">
                                    <FireworksCanvas />
                                    <div className="winner-container">
                                      <div className="d-flex gap-2">
                                        <div className="content_design">
                                          <div className="blast-container"></div>
                                          <div className="winner-header">

                                            {/* <div className="dice-container">
                                              <Dice />
                                            </div> */}
                                            🏆<span className="linear-wipe"> Congratulations! </span>🏆

                                            <div className="dice-container right">
                                              <Dice />
                                            </div>
                                          </div>
                                          {/* Winner Name and Message */}
                                          <div className="winner-name">Winner: {userName}</div>
                                          <div className="winner-message">
                                            You've conquered the game with strategy and luck!
                                            <span className="trophy-icon">🏆</span>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="cheers">
                                        <img src={process.env.PUBLIC_URL + "/Images/banner/cheers.gif"} alt="" />

                                      </div>
                                      <div className="cheers left">
                                        <img src={process.env.PUBLIC_URL + "/Images/banner/cheers.gif"} alt="" />

                                      </div>
                                      <div className="winneremoji winthegame">
                                        <img src={process.env.PUBLIC_URL + "/Images/banner/imagepopup.png"} alt="" />
                                      </div>
                                      <div className="winneremoji winthegame left">
                                        <img src={process.env.PUBLIC_URL + "/Images/banner/imagepopup.png"} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                )
                                  : userResult == "winn" && tournament.tournamentComplete == 'pending' ? (
                                    <div className="winresult image_height">
                                      <div className="winnersize">
                                        <div className="winner-container">
                                          <div className="d-flex gap-2">
                                            <div className="content_design">
                                              <div className="blast-container"></div>
                                              <div className="winner-header">

                                                {/* <div className="dice-container">
                                                  <Dice />
                                                </div> */}
                                                🏆<span className="linear-wipe"> Congratulations! </span>🏆

                                                <div className="dice-container right">
                                                  <Dice />
                                                </div>
                                              </div>
                                              {/* Winner Name and Message */}
                                              <div className="winner-name">Winner: {userName}</div>
                                              <div className="winner-message">
                                                <span className="trophy-icon">🏆</span>

                                                You've successfully made it to the next level of the tournament!
                                                <span className="trophy-icon">🏆</span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="cheers">
                                            <img src={process.env.PUBLIC_URL + "/Images/banner/cheers.gif"} alt="" />

                                          </div>
                                          <div className="cheers left">
                                            <img src={process.env.PUBLIC_URL + "/Images/banner/cheers.gif"} alt="" />

                                          </div>
                                          <div className="winneremoji">
                                            <img src={process.env.PUBLIC_URL + "/Images/banner/imagepopup.png"} alt="" />
                                          </div>
                                          <div className="winneremoji left">
                                            <img src={process.env.PUBLIC_URL + "/Images/banner/imagepopup.png"} alt="" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : userResult == "conflit" ? (
                                    <div className="conflitresult image_height">
                                      {/* <img src={process.env.PUBLIC_URL + "/Images/Homepage/WFR.png"} alt="" /> */}
                                      <div className="winresult image_height">
                                        <div className="winnersize">
                                          <div className="winner-container">
                                            <div className="d-flex gap-2">
                                              <div className="loadernew">
                                                <span>⏳</span>
                                              </div>

                                              <div className="content_design padding_content">
                                                <div className="winner-header">

                                                  <div className="dice-container left_design">
                                                    <Dice />
                                                  </div>
                                                  &nbsp;&nbsp;&nbsp;&nbsp;<span className="linear-wipe"> Waitng for Result! </span>&nbsp;&nbsp;&nbsp;&nbsp;

                                                  <div className="dice-container right pending_wait">
                                                    <Dice />
                                                  </div>
                                                </div>
                                                {/* Winner Name and Message */}
                                                <div className="winner-name">Winner: Pending</div>
                                                <div className="winner-message w-100">
                                                  ⏳   Please Wait for a Result....⏳
                                                </div>
                                              </div>
                                              <div className="loadernew right" >
                                                <span>⏳</span>
                                              </div>
                                            </div>

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null
                              }
                            </div>

                            <div className="prize-pool-content mx-3">
                              <div className="tournament-entries">
                                <div className="heading">Tournament Entries
                                  <div>{tournament.date}</div>
                                </div>
                                <div className="progress-bar" style={{ backgroundColor: '#e0e0e0' }}>
                                  <div
                                    className="progress"
                                    style={{
                                      width: `${(tourmentJoinLenght / tournament.total_user) * 100}%`,
                                      backgroundColor: '#4caf50'
                                    }}
                                  ></div>
                                </div>
                                <div className="fill-amount">
                                  {tourmentJoinLenght}/{tournament.total_user} Filled
                                </div>
                              </div>


                              <ul className="tournament-statistics">
                                <li>
                                  <i className="fa fa-ticket"></i>
                                  <div className="entry">
                                    <div className="title">Fee Per Entry</div>
                                    <div className="amount">₹{enteyFee}</div>
                                  </div>
                                </li>
                                <li>
                                  <i className="fa fa-award"></i>
                                  <div className="entry">
                                    <div className="title">First Prize</div>
                                    <div className="amount">₹{showFirstPrize}</div>
                                  </div>
                                </li>
                              </ul>
                            </div>

                            <div className="my-3 mx-3 distribution">
                              <div className="prize_title">Prize Distribution</div>
                              <div className="d-flex justify-content-between">
                                <div className="contentboard">Rank</div>
                                <div className="contentboard">Winning</div>
                              </div>
                              {showWinnerPrizes && showWinnerPrizes.length > 0 && (
                                <>
                                  {showWinnerPrizes.map((prizeData, idx) => {
                                    const { lavel, prize } = prizeData;
                                    return (
                                      <div key={idx} className="d-flex justify-content-between pricerank">
                                        <div className="bgimage_new prize text-dark">
                                          {lavel} 
                                        </div>
                                        <div className="prize text-dark padding_15 text-right">
                                          <span>₹{prize}</span> 
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              )}
                            </div>

                            <div className="game-rule-faq mx-3 mb-3">
                              <div className="title">
                                Game Rules & FAQ
                                {/* <button type="button" className="btn-language-change btn btn-primary">
                              हिंदी
                            </button> */}
                              </div>
                              <div className="faq-section">
                                <div className="accordion" id="gameRulesFaqAccordion">
                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                      <button
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                      >
                                        Game Rules
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseOne"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingOne"
                                      data-bs-parent="#gameRulesFaqAccordion"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          <span className="bold" style={{ fontSize: "15px" }}>Total Round of Game:</span> It depends on total count of users.
                                        </p>
                                        <p>
                                          <span className="bold" style={{ fontSize: "15px" }}>Pair Management:</span> The system will create the pair of the users. The pair will be generated automatically for each user according to the level of the tournaments.
                                        </p>
                                        <p>
                                          <span className="bold d-block" style={{ fontSize: "15px" }}>Level Creations:</span>
                                        </p>
                                        <ul>
                                          <li>
                                            Any user who wins a level will be eligible for the next round. If a user loses, they will be eliminated from the tournament. The system will notify users about their level completion status
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo"
                                      >
                                        Game Tie: Prize Distribution [Important]
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseTwo"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingTwo"
                                      data-bs-parent="#gameRulesFaqAccordion"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          The system will identify the top winners based on the points earned from each level. In every tournament, the top winners are determined according to the points earned during the game.
                                        </p>
                                        <p>
                                          <span className="bold d-block" style={{ fontSize: "15px" }}>1. Points distributions and Winning:</span>
                                        </p>
                                        <p>
                                          Each game win earns 2 points, and the top winners will be determined based on the total points they accumulate from their game wins.
                                        </p>
                                        <p>Each tournament has their different prize winners.</p>

                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                      >
                                        Frequently Asked Questions
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseThree"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingThree"
                                      data-bs-parent="#gameRulesFaqAccordion"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          <span className="bold d-block" style={{ fontSize: "15px" }}>What is the time limit?</span>
                                          The time limit for each game is 10 minutes. During this time, players will compete against their opponents and receive results indicating a win or loss to earn points. The total duration of the tournament will be around 3 to 4 hours
                                        </p>
                                        <p>
                                          <span className="bold d-block" style={{ fontSize: "15px" }}> How can I earn points?</span>
                                          You earn 2 points for winning a game against your competitors. This follows the same basic concept as the classic game of Ludo. Each game winning will provide you 2 points.
                                        </p>

                                        <p>
                                          <span className="bold d-block" style={{ fontSize: "15px" }}> Can I play game multiple times for a single level?</span>
                                          You can only play once for each level.
                                        </p>

                                        <p>
                                          <span className="bold d-block" style={{ fontSize: "15px" }}>When does the tournament start?</span>
                                          The tournament starts when the specified number of users will be fulfilled,
                                        </p>
                                        <p>
                                          <span className="bold d-block" style={{ fontSize: "15px" }}>Can I view the scores and rankings of other participants?</span>
                                          Yes, you can view the rankings of all participants in the tournament on the leaderboard.
                                        </p>
                                        <p>
                                          <span className="bold d-block" style={{ fontSize: "15px" }}>Is there an option to skip the game?</span>
                                          Skipping your game is not allowed. If you skip or cancel, you will be disqualified from the tournament.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      )}

                      {activeTab === "leaderboard" && (
                        <div className="mt-2">

                          <div className='winner_tab'>
                            <button
                              className={`${css.tabButton} ${activeTab1 === "joinerlist" ? css.activeTab : ""}`}
                              onClick={() => { setActiveTab1("joinerlist"); }}>
                              Joiner list
                            </button>
                            <button className={`${css.tabButton} ${activeTab1 === "pairlist" ? css.activeTab : ""}`}
                              onClick={() => setActiveTab1("pairlist")}>
                              Pair list
                            </button>
                          </div>

                          {activeTab1 === "joinerlist" && (
                            <>
                              <div className="joiner_table d-flex justify-content-center leaderboard leaderboardesign mt-2">
                                <div className="table-responsive">
                                  <table className="table text-center">
                                    <thead>
                                      <tr>
                                        <th></th>
                                        <th>User Name</th>
                                        <th>Score</th>
                                        <th>Level</th>
                                        <th>Results</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {leaderboardData.length > 0 ? (
                                        leaderboardData
                                          .sort((a, b) => b.point - a.point)
                                          .map((entry, idx) => (
                                            <tr key={idx}>
                                              <td>
                                                <picture>

                                                  {entry && entry.profile ? (
                                                    <img
                                                      className="mr-1"
                                                      src={`https://kycjvt.rushkingludo.com/${entry && entry.profile}`}
                                                      alt="avtar"

                                                    />
                                                  ) : (
                                                    <img
                                                      className="mr-1"
                                                      src={process.env.PUBLIC_URL + `Images/user.png`}
                                                      alt="user"

                                                    />
                                                  )}
                                                </picture>
                                              </td>
                                              <td>
                                                {entry.username}</td>
                                              <td>{entry.point}</td>
                                              <td>{entry.lavel}</td>
                                              <td
                                                style={{
                                                  color:
                                                    entry.result === "awaiting"
                                                      ? "orange"
                                                      : entry.result === "win" || entry.result === "winn"
                                                        ? "green"
                                                        : "red",
                                                }}
                                              >
                                                {entry.result}
                                              </td>
                                            </tr>
                                          ))
                                      ) : (
                                        <tr>
                                          <td colSpan="3">No leaderboard data available.</td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="col-12 text-center mt-3">
                                <div className="pagination-controls d-flex justify-content-center">
                                  <button
                                    onClick={() => paginate(currentPage - 1)}
                                    disabled={currentPage === 1}
                                    className="pagination-button"
                                  >
                                    <RiArrowLeftSLine />
                                  </button>
                                  {[...Array(totalPages)].map((_, idx) => (
                                    <button
                                      key={idx}
                                      onClick={() => paginate(idx + 1)}
                                      className={`pagination-button ${currentPage === idx + 1 ? "active" : ""}`}
                                    >
                                      {idx + 1}
                                    </button>
                                  ))}
                                  <button
                                    onClick={() => paginate(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                    className="pagination-button"
                                  >
                                    <RiArrowRightSLine />
                                  </button>
                                </div>
                              </div>
                            </>

                          )}

                          {activeTab1 === "pairlist" && (
                            <>
                              <div className="joiner_table bg-light rounded creator_table table-responsive mt-2">
                                <table className="table">
                                  <>
                                    <tr>
                                      <th>#</th>
                                      <th className="d-flex justify-content-center">Player 1</th>
                                      <th></th>
                                      <th className="d-flex justify-content-center">Player 2</th>
                                    </tr>
                                  </>
                                  <>
                                    {pairJoin && pairJoin.map((game, key) => (
                                      <tr key={key}>
                                        <td>{key + 1}</td>
                                        <td>
                                          <span className="pl-2">


                                            {game && game.CreaterProfile ? (
                                              <img
                                                className="mr-1"
                                                src={`https://kycjvt.rushkingludo.com/${game && game.CreaterProfile}`}
                                                alt="avtar"
                                              />
                                            ) : (
                                              <img
                                                className="mr-1"
                                                src={process.env.PUBLIC_URL + `Images/user.png`}
                                                alt="user"

                                              />
                                            )}
                                            {game.CreaterUserName}</span>
                                        </td>
                                        <td> <img
                                          src={
                                            process.env.PUBLIC_URL + "/Images/Homepage/versus.png"
                                          }
                                          alt=""
                                          width="21px"
                                        /></td>
                                        <td>
                                          <span className="pl-2">


                                            {game && game.JoinerProfile ? (
                                              <img
                                                src={`https://kycjvt.rushkingludo.com/${game && game.JoinerProfile}`}
                                                alt="avtar"
                                                className="mr-1"
                                              />
                                            ) : (
                                              <img
                                                src={process.env.PUBLIC_URL + `Images/user.png`}
                                                alt="user"
                                                className="mr-1"
                                              />
                                            )}
                                            {game.JoinerUserName}</span>
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                </table>
                                <div className="pagination-controls mb-2">
                                  <button
                                    onClick={() => paginate(currentPage - 1)}
                                    disabled={currentPage === 1}
                                  >
                                    <RiArrowLeftSLine />
                                  </button>
                                  {[...Array(totalPages)].map((_, idx) => (
                                    <button
                                      key={idx}
                                      onClick={() => paginate(idx + 1)}
                                      className={currentPage === idx + 1 ? 'active' : ''}
                                    >
                                      {idx + 1}
                                    </button>
                                  ))}
                                  <button
                                    onClick={() => paginate(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                  >
                                    <RiArrowRightSLine />
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                        </div>

                      )}
                    </div>
                  </div>
                  <div className="fixclassprize">
                    {userResult == "lose" ? (
                      <div className={`d-flex w-100 mt-2 text-center justify-content-center ${css.joinNowButton}`}>
                        👎 You have lost 👎
                      </div>
                    ) : userResult == "winn" && tournament.tournamentComplete == 'pending' ? (
                      <Link
                        to={{ pathname: `/tourmentresult/${playerid}` }}
                        style={{ bottom: "0" }}
                      >
                        <button className={`d-flex w-100 mt-2 text-center justify-content-center ${css.joinNowButton}`}>
                          Play Next Level 🚀
                        </button>
                      </Link>
                    )
                      : userResult == "winn" && tournament.tournamentComplete == 'complete' ? (
                        <>
                          <button className={`d-flex w-100 mt-2 text-center justify-content-center ${css.joinNowButton}`}>
                            You’re the 🏆<span>Champion! </span>
                          </button>
                        </>

                      )

                        : isUserPaired ? (
                          <Link
                            to={{ pathname: `/tourmentresult/${playerid}` }}
                            style={{ bottom: "0" }}
                          >
                            <button className={`d-flex w-100 mt-2 text-center justify-content-center ${css.joinNowButton}`}>
                              Play
                            </button>
                          </Link>
                        )

                          : isJoining ? (
                            <div className={`d-flex w-100 mt-2 text-center justify-content-center ${css.joinNowButton}`}>
                              You have joined
                            </div>
                          ) :

                            tournament.user_join == "yes" ? (
                              <button
                                className={`d-flex w-100 mt-2 text-center justify-content-center ${css.joinNowButton}`}
                                onClick={() => {
                                  setIsJoining(true);
                                  handleJoinNow(tournament.t_id);
                                }}
                              >
                                Join Now ₹{tournament.entry_fees}
                              </button>
                            ) :

                              (

                                <button className={`d-flex w-100 mt-2 text-center justify-content-center ${css.joinNowButton}`}>
                                  ⏳🙄 Please Wait.. ⏳🙄
                                </button>
                              )}
                  </div>

                </>

              ))
            ) : (
              <picture className="gameCard-image rounded-lg">
                <img
                  width="100%"
                  src={process.env.PUBLIC_URL + "/Images/Homepage/Tournament-Poster.png"}
                  alt=""
                />
              </picture>
            )}
          </div >
        )}
      </div>
    </div>

  );
};

export default Tournament;
